<template>
  <div class="container">
    <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
      <a-breadcrumb-item>系统设置</a-breadcrumb-item>
      <a-breadcrumb-item>设备连接</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="mainBox" style="padding: 24px">
      <div v-if="!agent_inst" style="text-align: center">
        <h2>本功能已迁移至“食堂管理专家”小程序，使用更便捷，扫码即可前往！</h2>
        <p>
          <img
            src="https://styf-jf-oss.oss-cn-shenzhen.aliyuncs.com/productions/202009/%E9%A3%9F%E5%A0%82%E7%AE%A1%E7%90%86%E4%B8%93%E5%AE%B6.jpg"
            alt
            srcset
          />
        </p>
      </div>
      <div v-else style="text-align: center">
        <h2>本功能已迁移至“食堂后台管理”小程序，使用更便捷，扫码即可前往！</h2>
        <p>
          <img
            src="https://styf.oss-cn-shenzhen.aliyuncs.com/business/productions/202108/162798478197723.jpg"
            alt
            srcset
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {} from "@/request/api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      agent_inst: false
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.agent_inst = JSON.parse(localStorage.getItem("agent_inst"));
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.controlBox {
  display: flex;
  .btn {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}
</style>